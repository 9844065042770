<template>
    <div>
        <card-table-search title="Vieux Clujistes" :starts-open="false" @collapse="loadUtilisateurs"
                           :fields="fieldsUtilisateurs" :actions="actionsUtilisateurs" :items="utilisateurs"
                           :custom-rendered="['prenom']">
            <template #prenom="{row}">
                <user-name :user="row.item"/>
            </template>
        </card-table-search>
        <card-table-search title="JdR" :starts-open="false" @collapse="loadJdrs"
                           :fields="fieldsObjects" :actions="actionsJdrs" :items="jdrs"/>
        <card-table-search title="Jeux" :starts-open="false" @collapse="loadJeux"
                           :fields="fieldsObjects" :actions="actionsJeux" :items="jeux"/>
        <card-table-search title="Livres" :starts-open="false" @collapse="loadLivres"
                           :fields="fieldsObjects" :actions="actionsLivres" :items="livres"/>
    </div>
</template>

<script>
    import {apiPath}                   from '@/util/http';
    import {momentDates, textUsername} from '@/util/user';
    import {formatDate}                from '@/util/date';
    import alert                       from '@/util/alert';

    const UserName        = () => import('@/components/UserName');
    const CardTableSearch = () => import('@/components/CardTableSearch');

    export default {
        name: "AdminArchives",
        components: {UserName, CardTableSearch},
        data() {
            return {
                utilisateurs: [],
                jdrs: [],
                jeux: [],
                livres: [],
                fieldsUtilisateurs: [
                    {
                        key: 'prenom',
                        label: 'Nom',
                        sortable: true,
                        formatter: (value, key, item) => textUsername(item)
                    },
                    {
                        key: 'dateInscription',
                        label: "Date d'inscription",
                        sortable: true,
                        formatter: value => formatDate(value)
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                fieldsObjects: [
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actionsUtilisateurs: [
                    {
                        key: 'action_validate',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Re-valider',
                        handler: ({item}) => this.reValidateUser(item)
                    }
                ],
                actionsJdrs: [
                    {
                        key: 'action_validate',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Désarchiver',
                        handler: ({item}) => this.unarchiveJdr(item)
                    }
                ],
                actionsJeux: [
                    {
                        key: 'action_validate',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Désarchiver',
                        handler: ({item}) => this.unarchiveJeu(item)
                    }
                ],
                actionsLivres: [
                    {
                        key: 'action_validate',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: 'Désarchiver',
                        handler: ({item}) => this.unarchiveLivre(item)
                    }]
            }
        },
        methods: {
            loadUtilisateurs(collapseVisible) {
                if (collapseVisible) {
                    alert.loading();
                    this.axios.get(apiPath('admin_user_list', {role: 'ROLE_ANCIEN'}))
                        .then(response => this.utilisateurs = momentDates(response.data))
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des vieux Clujistes'))
                        .finally(alert.stopLoading);
                }
            },
            loadJdrs(collapseVisible) {
                if (collapseVisible) {
                    alert.loading();
                    this.axios.get(apiPath('admin_list_jdrs', {archive: 1}))
                        .then(response => this.jdrs = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des JdR archivés'))
                        .finally(alert.stopLoading);
                }
            },
            loadJeux(collapseVisible) {
                if (collapseVisible) {
                    alert.loading();
                    this.axios.get(apiPath('admin_list_jeux', {archive: 1}))
                        .then(response => this.jeux = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux archivés'))
                        .finally(alert.stopLoading);
                }
            },
            loadLivres(collapseVisible) {
                if (collapseVisible) {
                    alert.loading();
                    this.axios.get(apiPath('admin_list_livres', {archive: 1}))
                        .then(response => this.livres = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des livres archivés'))
                        .finally(alert.stopLoading);
                }
            },
            reValidateUser(user) {
                alert.loading();
                this.axios.post(apiPath('admin_revalidate_clujiste', {clujiste: user.id}))
                    .then(() => this.loadUtilisateurs(true))
                    .catch(() => this.$toaster.error('Impossible de ré-activer ce compte'))
                    .finally(alert.stopLoading);
            },
            unarchiveJdr(jdr) {
                alert.loading();
                this.axios.post(apiPath('admin_unarchive_jdr', {jdr: jdr.id}))
                    .then(() => this.loadJdrs(true))
                    .catch(() => this.$toaster.error('Impossible de désarchiver le JdR'))
                    .finally(alert.stopLoading);
            },
            unarchiveJeu(jeu) {
                alert.loading();
                this.axios.post(apiPath('admin_unarchive_jeu', {jeu: jeu.id}))
                    .then(() => this.loadJeux(true))
                    .catch(() => this.$toaster.error('Impossible de désarchiver le jeu'))
                    .finally(alert.stopLoading);
            },
            unarchiveLivre(livre) {
                alert.loading();
                this.axios.post(apiPath('admin_unarchive_livre', {livre: livre.id}))
                    .then(() => this.loadLivres(true))
                    .catch(() => this.$toaster.error('Impossible de désarchiver le livre'))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
